body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
  display: flex; /* Включаем Flexbox для контейнера */
  flex-direction: column; /* Располагаем элементы в колонку */
}

.photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
  border: 1px solid #E2231A;
}

.name {
  font-size: 24px;
  margin: 10px 0;
  color: #333;
}

.role {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.company {
  font-size: 16px;
  color: #E2231A;
  margin-bottom: 20px;
}

.contact-info {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

.link {
  display: inline-block;
  margin: 10px;
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.button {
  padding: 5px 10px;
/*   background-color: #ffffff; */
  /* color: white; */
  border: 1px solid #1D1D1B;
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.button:hover, .button:focus {
  /* background-color: #0056b3; */
  /* box-shadow: 0 0.5em 0.5em -0.4em #E2231A; */
  transform: translateY(-0.25em);
  /* border-color: #E2231A; */
}

a {
  padding: 5px 10px;
/*   background-color: #ffffff; */
  /* color: white; */
  /* border: 1px solid #1D1D1B; */
  /* border-radius: 7px; */
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}
a:hover, a:focus {
  /* background-color: #0056b3; */
  /* box-shadow: 0 0.5em 0.5em -0.4em #E2231A; */
  transform: translateY(-0.25em);
  /* border-color: #E2231A; */
}

.button-container {
  display: flex; /* Включаем Flexbox для кнопок */
  gap: 10px; /* Задаем расстояние между кнопками */
  justify-content: center; /* Центрируем кнопки по горизонтали */
  padding: 20px; /* Отступы внутри контейнера */
}

.form-container {
  margin-top: 20px;
  text-align: left;
}

form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  margin-top: 10px;
}

.footer {
  display: flex; /* Flexbox для футера */
  justify-content: center; /* Выравниваем логотип по левому краю */
  align-items: center; /* Выравниваем логотип по нижнему краю */
  padding: 10px; /* Внутренние отступы футера */
}
